<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-29 15:08:39
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-11 23:19:53
-->
<template>
  <div>
    <div class="notice">注：新密码请设置不少于8位数</div>
    <form action="" class="form">
      <h2 class="title flex-x center between">
        <span>新用户注册</span>
        <span class="login" @click="$router.back()">返回登录</span>
      </h2>
      <div class="row">
        <div class="row__title">姓名</div>
        <input
          class="row__input"
          type="text"
          placeholder="请输入姓名"
          v-model="query.name"
        />
      </div>
      <div class="row">
        <div class="row__title">手机号</div>
        <input
          class="row__input"
          type="text"
          placeholder="输入手机号码"
          v-model="query.mobile"
        />
      </div>
      <div class="row">
        <div class="row__title">验证码</div>
        <input
          class="row__input"
          type="text"
          placeholder="请输入验证码"
          v-model="query.smsCode"
        />
        <button class="row__button" type="button" @click="sendCode">
          {{ smsMsg }}
        </button>
      </div>
      <div class="row">
        <div class="row__title">输入密码</div>
        <input
          class="row__input"
          type="password"
          placeholder="请输入密码"
          v-model="query.password"
        />
      </div>
      <!-- <div class="row">
        <div class="row__title">重新输入</div>
        <input
          class="row__input"
          type="password"
          placeholder="请再次输入密码"
        />
      </div> -->
      <div class="row row--noline" @click="showArea = true">
        <div class="row__title">所在城市</div>
        <input
          class="row__input"
          type="text"
          disabled
          v-model="query.province"
        />
        <div class="row__text">省</div>
        <input class="row__input" type="text" disabled v-model="query.city" />
        <div class="row__text">市</div>
      </div>

      <div class="row row--noline">
        <div class="row__title">工种</div>
        <div class="row__text">(多选)</div>
      </div>

      <div class="row-tags flex-x center between wrap">
        <div
          class="row__tag"
          :class="item.selected ? 'row__tag--active' : ''"
          v-for="(item, index) in workTypes"
          :key="'worktype' + index"
          @click="selectWorkType(index)"
        >
          {{ item.name }}
        </div>
        <div class="row__tag hidden" v-for="item in 3" :key="item"></div>
      </div>

      <div class="row row--noline">
        <div class="row__title">证件照</div>
      </div>

      <div class="flex-x center between">
        <div class="row-upload" @click="selectImg('idFront')">
          <div class="img-box row-upload__img">
            <img
              v-if="!idFrontDisplay"
              src="../../../assets/images/service/id_front.png"
              alt=""
            />
            <img v-else :src="idFrontDisplay" alt="" />
          </div>
          <span class="row-upload__text">添加身份证正面</span>
        </div>
        <div class="row-upload" @click="selectImg('idBack')">
          <div class="img-box row-upload__img">
            <img
              v-if="!idBackDisplay"
              src="../../../assets/images/service/id_back.png"
              alt=""
            />
            <img v-else :src="idBackDisplay" alt="" />
          </div>
          <span class="row-upload__text">添加身份证反面</span>
        </div>
      </div>

      <div class="row row--noline">
        <div class="row__title">银行卡</div>
      </div>

      <div class="flex-x center">
        <div class="row-upload">
          <div class="img-box row-upload__img" @click="selectImg('bank')">
            <img
              v-if="!bankDisplay"
              src="../../../assets/images/service/bank_front.png"
              alt=""
            />
            <img v-else :src="bankDisplay" alt="" />
          </div>
          <span class="row-upload__text">添加银行卡正面</span>
        </div>
      </div>

      <button class="confirm" type="button" @click="register">注册</button>
    </form>

    <van-popup v-model="showArea" position="bottom">
      <van-area
        :areaList="areaList"
        columns-num="2"
        @confirm="selectArea"
        @cancel="showArea = false"
      ></van-area>
    </van-popup>

    <msg-box
      v-model="showWaiting"
      title="提交成功"
      content="请等待审核通过"
      @onclick="$router.back()"
    />
  </div>
</template>

<script>
import { register, upload } from "../../../utils/api/utils";
import { Area, Popup, Toast } from "vant";
import areaList from "../../../utils/area";
import { workType } from "../../../utils/api/service";
import { sendSms } from "../../../utils/api/utils";
import isIos from "../../../utils/checkDevice";
import msgBox from "../../../components/msgBox/msgBox";
let smsInterval;
export default {
  components: {
    "van-area": Area,
    "van-popup": Popup,
    "msg-box": msgBox,
  },
  data() {
    return {
      showWaiting: false, // 显示提交成功待审核
      areaList, // 地址列表
      showArea: false, // 显示地址列表
      query: {
        // 注册接口数据
        name: "",
        mobile: "",
        password: "",
        smsCode: "",
        province: "",
        city: "",
        typeWork: "",
        idCard: {
          positive: "",
          negative: "",
        },
        bankCard: "",
      },
      workTypes: [], // 工种列表

      idFront: "", // 身份证正面
      idFrontDisplay: "",

      idBack: "", // 身份证背面
      idBackDisplay: "",

      bank: "", // 银行卡
      bankDisplay: "",

      smsMsg: "发送验证码",
    };
  },

  created() {
    workType().then((res) => {
      this.workTypes = res.data.data.list;
    });
  },

  methods: {
    // 选择地址
    selectArea(e) {
      this.query.province = e[0].name;
      this.query.city = e[1].name;
      this.showArea = false;
    },

    // 选择图片上传
    selectImg(key) {
      this.$wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          let pic = res.localIds[0];
          if (isIos()) {
            this.$wx.getLocalImgData({
              localId: pic,
              success: function (res) {
                this[key] = pic;
                this[key + "Display"] = res.localData;
              }.bind(this),
            });
          } else {
            this[key] = pic;
            this[key + "Display"] = pic;
          }
          // var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        }.bind(this),
      });
    },

    // 选择工种
    selectWorkType(index) {
      let workTypes = JSON.parse(JSON.stringify(this.workTypes));
      workTypes[index].selected = !workTypes[index].selected;
      this.workTypes = workTypes;
    },

    // 发送验证码
    sendCode() {
      let initSmsMsg = "发送验证码";
      if (this.smsMsg !== initSmsMsg) return false;

      sendSms({
        mobile: this.query.mobile,
      })
        .then(() => {})
        .catch((res) => {
          Toast(res.data.msg);
          this.clearCountDown();
        });

      let second = 60;

      let setSecond = function () {
        this.smsMsg = second;
        second--;
        if (second < 0) {
          this.clearCountDown();
        }
      }.bind(this);

      setSecond();

      smsInterval = setInterval(setSecond, 1000);
    },

    clearCountDown() {
      clearInterval(smsInterval);
      this.smsMsg = "发送验证码";
    },

    // 注册
    async register() {
      let query = this.query;
      if (!query.name) return Toast("请输入名字");
      if (!query.mobile) return Toast("请输入手机号");
      if (!query.smsCode) return Toast("请输入验证码");
      if (!query.city) return Toast("请选择所在省市");
      if (!query.password) return Toast("请输入密码");
      if (!this.idFront) return Toast("请上传身份证正面");
      if (!this.idBack) return Toast("请上传身份证反面");
      if (!this.bank) return Toast("请上传银行卡正面");
      let workTypes = this.workTypes,
        selectTypes = [];
      workTypes.forEach((item) => {
        if (item.selected) selectTypes.push(item.value);
      });
      if (selectTypes.length === 0) return Toast("请选择工种");
      query.typeWork = JSON.stringify(selectTypes);

      // 上传身份证正面
      query.idCard.positive = await this.uploadImg(this.idFront);
      if (!query.idCard.positive) return Toast("上传身份证正面失败");
      // 上传身份证背面
      query.idCard.negative = await this.uploadImg(this.idBack);
      if (!query.idCard.negative) return Toast("上传身份证背面失败");
      // 上传银行卡正面
      query.bankCard = await this.uploadImg(this.bank);
      if (!query.bankCard) return Toast("上传银行卡失败");

      query.idCard = JSON.stringify(query.idCard);
      register({
        ...query,
      })
        .then(() => {
          this.showWaiting = true;
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 上传图片
    uploadImg(id) {
      return new Promise((resolve, reject) => {
        this.$wx.uploadImage({
          localId: id,
          success(res) {
            let uploadId = res.serverId;
            upload({
              mediaId: uploadId,
            })
              .then((res) => {
                resolve(res.data.data.input);
              })
              .catch((res) => {
                reject(res);
              });
          },
        });
      });
    },
  },

  beforeDestroy() {
    this.clearCountDown();
  },
};
</script>

<style src="@/assets/css/review/forget.css" scoped>
</style>
<style  src="../../../assets/css/service/register.css" scoped>
</style>